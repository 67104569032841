import Vue from "vue";
import { mdiFacebook, mdiLinkedin } from "@mdi/js";

export default Vue.extend({
  name: "AppFooterComponent",
  data() {
    return {
      IconFacebook: mdiFacebook,
      IconLinkedIn: mdiLinkedin,
    };
  },
});
